import Link from "next/link";
import { Trans, t } from "@lingui/macro";
import { moneyFormat } from "@/utils/moneyFormat";
import { getLocale } from "@/utils/locale";
import {
  handleProductModal,
  handleModalType,
  handleLeadModal,
} from "@/features/leadModal/leadModalSlice";
import {
  handleAddToCartModal,
  handleProductAddToCartModal,
  handleUpdateItemModal,
} from "@/features/addToCart/addToCartSlice";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import {
  Category,
  CategoryThumbnailType,
  getImageFromCategory,
} from "@/app/dto/category";
import Image from "next/image";

interface Props {
  category: Category;
  priority?: boolean;
}

export default function Product({ category, priority }: Props) {
  const locale = getLocale();
  const dispatch = useDispatch();

  const getPreferedPrice = (): [number | null, string | null] => {
    if (category?.hourly_price) {
      return [category.hourly_price, t`hourly`];
    }
    if (locale === "es-MX") {
      return [category?.monthly_price, t`monthly`];
    }

    if (category?.daily_price) {
      return [category?.daily_price, t`daily`];
    } else if (category?.weekly_price) {
      return [category?.weekly_price, t`weekly`];
    } else if (category?.monthly_price) {
      return [category?.monthly_price, t`monthly`];
    } else {
      return [null, null];
    }
  };

  const [preferedPrice, preferedPriceTemporality] = useMemo(getPreferedPrice, [
    category,
    locale,
  ]);

  const openLeadModal = () => {
    dispatch(handleProductModal(category.id));
    dispatch(handleLeadModal(true));
  };

  const handleLeaseModal = () => {
    dispatch(handleModalType({ type: "lease", category: category.id }));
    openLeadModal();
  };
  const handleBuyModal = () => {
    dispatch(handleModalType({ type: "sale", category: category.id }));
    openLeadModal();
  };

  const handleOpenAddToCartModal = () => {
    dispatch(handleAddToCartModal(true));
    dispatch(handleUpdateItemModal(null));
    dispatch(handleProductAddToCartModal(category));
    dispatch(handleAddToCartModal(true));
  };

  const image = getImageFromCategory(category, CategoryThumbnailType.Small);

  return (
    <>
      <div className="max-w-full mx-auto mt-24 w-80 lg:mt-16 hover:duration-400 bg-[var(--primary-color)] hover:shadow-[0px_3px_24px_#2f536d1f] duration-200 ease-in-out">
        <div className="relative w-full h-48 bg-white rounded-t-md ">
          <div className="absolute w-full h-60 -top-10">
            <Link
              href={{
                pathname: `/${locale}/category/${encodeURIComponent(
                  category.slug
                )}`,
              }}
            >
              {image !== null ? (
                <div className="relative w-auto h-full m-auto cursor-pointer z-2 ">
                  <Image
                    layout="fill"
                    objectFit="contain"
                    priority={priority}
                    src={image}
                    alt={category.title}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </Link>
          </div>
        </div>
        <Link
          href={{
            pathname: `/category/${encodeURIComponent(category.slug)}`,
          }}
        >
          <div className="w-full p-2 pb-3 bg-alice-blue-150 z-1 h-30 lg:h-28 cursor-pointer">
            <h3 className="min-h-[64px] pb-4 font-bold cursor-pointer">
              <a cy-data="product-link">{category.title}</a>
            </h3>
            <p className="flex items-center justify-between gap-1">
              <span className="text-xs w-fit">
                {t`rent ${preferedPriceTemporality} from`}
              </span>
              {preferedPrice === null ? (
                <span className="font-semibold">
                  <Trans>To quote</Trans>
                </span>
              ) : (
                <span className="font-semibold text-right w-full text-fiery-rose">
                  {locale === "es-MX"
                    ? moneyFormat(preferedPrice, locale)
                    : moneyFormat(preferedPrice, locale)}
                  {locale === "pt-BR" ? (
                    <small className="text-xs">
                      {" "}
                      / <Trans>Dia</Trans>
                    </small>
                  ) : (
                    <small className="text-xs">
                      + <Trans>VAT</Trans>
                    </small>
                  )}
                </span>
              )}
            </p>
          </div>
        </Link>
        <span className="inline-flex w-full z-3">
          <button
            type="button"
            className="w-[50%] -ml-px relative inline-flex items-center px-4 py-3 bg-safety-orange-80 text-safety-orange text-sm rounded-bl-md
            justify-center font-medium hover:bg-fiery-rose-80 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 
            focus:border-indigo-500"
            onClick={handleBuyModal}
            cy-data="product-quote-purchase-button"
          >
            <Trans>Quote purchase</Trans>
          </button>
          <button
            cy-data="product-quote-lease-button"
            type="button"
            className="w-[50%] -ml-px relative  inline-flex items-center px-4 py-3 rounded-br-md justify-center
            bg-gradient-to-r from-safety-orange to-fiery-rose hover:from-fiery-rose hover:to-safety-orange text-sm font-medium text-white hover:bg-gray-50 focus:z-10 
            focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 "
            onClick={handleLeaseModal}
          >
            <Trans>Quote rent</Trans>
          </button>
        </span>
      </div>
    </>
  );
}
